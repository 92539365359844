<template>
  <div>
    <button
      v-if="!showEmailAddressInput"
      class="button-link text-small"
      @click="showInput"
    >
      + Add email address
    </button>
    <div v-else class="add-email-wrapper">
      <input
        type="email"
        v-model="emailAddress"
        :disabled="isSaving"
        ref="emailAddressInput"
        @keyup.enter="add()"
        :class="isValid ? '' : 'invalid'"
      />
      <button @click="add" class="button-small">Add</button>
      <button @click="hideInput" class="button-link text-small">Cancel</button>
    </div>
  </div>
</template>

<script>
import { EMAIL_ADDRESS_REGEX } from "./../constants";

export default {
  name: "AppDashboardAddEmail",
  props: {
    sourceSystemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      emailAddress: "",
      isSaving: false,
      isValid: true,
      showEmailAddressInput: false,
    };
  },
  watch: {
    emailAddress(value) {
      this.emailAddress = value;
      this.isValid = this.validateEmailAddress(this.emailAddress);
    },
  },
  methods: {
    async add() {
      if (!this.isValid) return;

      this.isSaving = true;

      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(`./api/me/contact`, {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ContactTypeId: 6,
          Line1: this.emailAddress,
          SourceSystemId: this.sourceSystemId,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          return response.json();
        })
        .then((responseJson) => {
          this.isSaving = false;
          this.hideInput();
          this.$emitter.emit("emailAddressAdded", responseJson);
        })
        .catch((error) => {
          this.isSaving = false;
          console.log(error);
        });
    },
    validateEmailAddress(email) {
      return EMAIL_ADDRESS_REGEX.test(email);
    },
    showInput() {
      this.showEmailAddressInput = true;
      this.$nextTick(() => this.$refs.emailAddressInput.focus());
    },
    hideInput() {
      this.showEmailAddressInput = false;
      this.emailAddress = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

.add-email-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: $spacer-2 $spacer;

  input {
    grid-column: 1 / -1;
  }
}
</style>
