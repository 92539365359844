<template>
    <section>
        <h2>Waiting List Entries</h2>
        <article class="card">
            <div class="card-body card-grid">
                <h3>Entries</h3>
                <ul v-if="waitingListEntries && waitingListEntries.length">
                    <li v-for="entry in waitingListEntries" :key="entry.waitingListEntryId">
                        <p>
                            {{ entry.waitingListName }} (State: {{ entry.stateName }})
                        </p>
                    </li>
                </ul>
                <p v-else>No waiting list entries</p>
                <h3>Reset</h3>
                <button @click="resetWaitingListEntries" v-bind:disabled="isLoading">
                    Reset waiting list data
                </button>
                <div v-if="resetSuccess" class="reset-success-message">Waiting list data has been reset. Please finish configuring the waiting list with a booking team, clinic and specialty in the staff portal.</div>
            </div>
        </article>
    </section>
</template>

<script>
    export default {
        name: "AppDashboardWaitingList",
        props: {
            sourceSystemId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                waitingListEntries: [],
                isLoading: false,
                resetSuccess: false,
            };
        },
        mounted() {
            this.getWaitingListEntries();
        },
        methods: {
            getWaitingListEntries() {
                this.isLoading = true;
                const url = `./api/me/waitinglistentries/${this.sourceSystemId}`;
                const bearer = "Bearer " + this.$store.state.accessToken;

                fetch(url, {
                    method: "GET",
                    withCredentials: true,
                    credentials: "include",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong");
                        }
                    })
                    .then((data) => {
                        this.waitingListEntries = data;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.error("Error fetching waiting list entries:", error);
                        this.isLoading = false;
                    });
            },
            resetWaitingListEntries() {
                this.isLoading = true;
                this.resetSuccess = false;
                const url = `./api/me/waitinglistentries/reset/${this.sourceSystemId}`;
                const bearer = "Bearer " + this.$store.state.accessToken;

                fetch(url, {
                    method: "PUT",
                    withCredentials: true,
                    credentials: "include",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => {
                        if (response.ok) {
                            this.getWaitingListEntries();
                            this.resetSuccess = true;
                        } else {
                            throw new Error("Something went wrong");
                        }
                    })
                    .catch((error) => {
                        console.error("Error resetting waiting list entries:", error);
                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "./.././styles/abstracts/_variables.scss";

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .reset-success-message {
        font-style: italic;
        grid-column: 1 / -1;
    }
</style>
