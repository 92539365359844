<template>
  <section>
    <h2>Assessments</h2>
    <article class="card">
      <div class="card-body card-grid">
        <h3>Total</h3>
        <p>{{ assessments.totalCount }}</p>
        <h3>Upcoming</h3>
        <ul
          v-if="
            assessments.upcomingAssessments &&
            assessments.upcomingAssessments.length
          "
        >
          <li
            v-for="assessment in assessments.upcomingAssessments"
            :key="assessment.formInstanceId"
          >
            <p>
              {{ formatDateToBeReadable(assessment.dueDate) }}
              {{ assessment.clinicalName }}
            </p>
          </li>
        </ul>
        <p v-else>No upcoming assessments</p>
        <h3>Past</h3>
        <ul
          v-if="
            assessments.pastAssessments && assessments.pastAssessments.length
          "
        >
          <li
            v-for="assessment in assessments.pastAssessments"
            :key="assessment.formInstanceId"
          >
            <p>
              {{ formatDateToBeReadable(assessment.dueDate) }}
              {{ assessment.clinicalName }}
            </p>
          </li>
        </ul>
        <p v-else>No past assessments</p>
        <h3>Delete</h3>
        <button
          @click="deleteData"
          v-bind:disabled="isLoading"
          class="btn-delete"
        >
          Delete assessment data
        </button>
      </div>
    </article>
  </section>
</template>

<script>
import { formatDateToBeReadable } from "./../utils/DateUtils.js";
export default {
  name: "AppDashboardAssessments",
  props: {
    sourceSystemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      assessments: {},
      isLoading: false,
    };
  },
  mounted() {
    this.getAssessments();
  },
  methods: {
    deleteData() {
      this.isLoading = true;

      const url = `./api/me/assessments/${this.sourceSystemId}`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(url, {
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            this.getAssessments();
          } else {
            this.isLoading = false;
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getAssessments() {
      this.isLoading = true;

      const url = `./api/me/assessments/${this.sourceSystemId}`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(url, {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.isLoading = false;
            throw new Error("Something went wrong");
          }
        })
        .then((responseJson) => {
          this.assessments = responseJson;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    formatDateToBeReadable,
  },
};
</script>

<style lang="scss" scoped>
@import "./.././styles/abstracts/_variables.scss";

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.btn-delete {
  background: $danger-muted;
}
</style>
