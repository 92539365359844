<template>
  <section>
    <h2>Letter Upload</h2>
    <article v-if="!isUploading" class="card">
      <div class="card-body card-grid">
        <label for="file-upload" class="custom-file-upload">
          Upload a PDF
        </label>
        <input
          id="file-upload"
          type="file"
          accept="application/pdf"
          @change="handleFileSelection"
        />
        <label for="type-input">Type:</label>
        <input id="type-input" type="text" v-model="type" />
        <label for="appointmentId-input">AppointmentId: </label>
        <input id="appointmentId-input" type="text" v-model="appointmentId" />
        <button @click="submitForm">Upload</button>
        <small v-show="updateSuccess" class="success-message">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            class="svg-inline--fa fa-check fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
            ></path>
          </svg>
          Letter uploaded
        </small>
        <small v-show="updateFailure" class="failure-message">
          Letter failed to upload...please ask in #product-simplificator for help
        </small>
      </div>
    </article>
    <article v-if="isUploading" class="card">
      <div class="card-body">
        <strong>Upload in progress...</strong>
      </div>
    </article>
  </section>
</template>

<script>
const SUCCESS_MESSAGE_DISPLAY_TIME_MS = 5000;
const FAILURE_MESSAGE_DISPLAY_TIME_MS = 10000;

export default {
  props: {
    sourceSystemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      type: "Appointment",
      appointmentId: "",
      selectedFile: null,
      isUploading: false,
      updateSuccess: false,
      updateFailure: false,
    };
  },
  methods: {
    handleFileSelection(event) {
      this.selectedFile = event.target.files[0];
    },
    submitForm() {
      const bearer = "Bearer " + this.$store.state.accessToken;
      if (this.selectedFile) {
        this.isUploading = true;
        const formData = new FormData();
        formData.append("file", this.selectedFile);
        formData.append("type", this.type);
        formData.append("appointmentId", this.appointmentId);
        formData.append("sourceSystemId", this.sourceSystemId);

        fetch("./api/Me/Letter", {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              response.text().then((text) => {
                console.log(text);
              });
              throw new Error("Network response was not ok");
            }

            this.updateSuccess = true;
            setTimeout(() => {
              this.updateSuccess = false;
            }, SUCCESS_MESSAGE_DISPLAY_TIME_MS);

            this.isUploading = false;
          })
          .catch((error) => {
            this.isUploading = false;
            this.updateFailure = true;
            setTimeout(() => {
              this.updateFailure = false;
            }, FAILURE_MESSAGE_DISPLAY_TIME_MS);
            console.error(
              "There has been a problem with your fetch operation:",
              error,
            );
          });
      }
    },
  },
};
</script>

<style>
.success-message {
  align-items: center;
  animation: FADE_OUT 0.5s 1.5s ease-out forwards;
  color: var(--success);
  display: flex;
  font-weight: 400;
  margin-left: $spacer;

  svg {
    margin-right: $spacer-1;
    width: 1em;
  }
}

.failure-message {
  align-items: center;
  color: var(--danger);
  display: flex;
  font-weight: 400;
  margin-left: $spacer;

  svg {
    margin-right: $spacer-1;
    width: 1em;
  }
}
</style>
